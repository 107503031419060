<template>
  <div>
    <div class="row mt-5 voucher-home">
      <div class="col-12 col-lg-6 offset-lg-3 dual-box text-white p-4">
        <h3 class="mt-2">Please enter your employee number</h3>

        <div class="async-error" v-if="this.error">
          {{ this.errorMessage }}
        </div>

        <div class="mb-3">
          <b-form id="preassform" @submit.stop.prevent="onSubmit">
            <div class="d-flex">
              <div class="form-group flex-grow-1 mb-0 mr-1">
                <b-form-input
                  class="p-4"
                  id="employee-input"
                  name="employee-input"
                  v-model="$v.form.employeeNumber.$model"
                  placeholder="Enter employee number"
                  :state="validateState('employeeNumber')"
                ></b-form-input>

                <b-form-invalid-feedback style="font-size: small !important">Employee number required</b-form-invalid-feedback>
              </div>
              <button class="btn btn-light btn-lg text-hpcolour-green">
                Submit
              </button>
            </div>
          </b-form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as CampaignAccessCache from "./../modules/CampaignAccessCache";
import { VerifyEmployee } from "./../modules/VerifyEmployee";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
export default {
  mixins: [validationMixin],
  data() {
    return {
      form: {
        employeeNumber: null,
      },
      campaignAccess: null,
      error: false,
      errorMessage: null,
    };
  },
  validations: {
    form: {
      employeeNumber: {
        required,
      },
    },
  },
  async created() {
    try {
      this.campaignAccess =
        await CampaignAccessCache.getCampaignAccessCacheByCodeCheckVoucherLimit(
          this.$route.params.code
        );
    } catch (e) {
      this.errorMessage = e;
      this.error = true;
      return;
    }
  },
  methods: {
    async onSubmit() {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }
      let employeeNumber = this.form.employeeNumber.trim();
      if (employeeNumber) {
        employeeNumber = employeeNumber.replace(/\s/g, "").substring(0, 64)
      }
      try {
        // const verifyEmployee = new VerifyEmployee(this.campaignAccess);
        // verifyEmployee.validateMinMaxValues(employeeNumber);
        // verifyEmployee.validateRegExPattern(employeeNumber);
        localStorage.hpFluEmployeeNumber = employeeNumber;
        // this.navigatePage("PreRegister", {
        //   code: this.$route.params.code,
        // });
        this.navigatePage("Postcode", { code: this.$route.params.code });
      } catch (e) {
        this.errorMessage = e;
        this.error = true;
        return;
      }
    },
    navigatePage(page, params) {
      if (params) {
        this.$router.push({ name: page, params: params });
        return;
      }
      this.$router.push({ name: page });
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
  },
};
</script>